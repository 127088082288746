import * as React from "react";
import "../assets/styles/Index.css";
import Logo from "../assets/images/R_R-logo2-02.png";
import { navigate } from "gatsby";
// markup
const NotFoundPage = () => {
  const goBackHandler = () => {
    navigate(-1);
  };
  return (
    <main className="min-h-screen justify-center flex items-center font-bold text-2xl">
      <a
        href="https://www.rplusrprogram.com/"
        className="absolute top-5 right-0 bg-app-certificate-accent"
      >
        <div className="block justify-center items-center">
          <img src={Logo} className="pr-20" width={200} alt="logo404"></img>
        </div>
      </a>
      <div className="text-center">
        <span className="block"> Page Not Found</span>
        <button onClick={goBackHandler}> Go Back</button>
      </div>
    </main>
  );
};

export default NotFoundPage;
